#skills {
  padding: 50px;
  font-family: 'Centra';
}

.skills-light {
  color: black;
  background-image: url('../../Assets/Icons/circle-light.svg');
}

.skills-dark {
  color: white;
  background-image: url('../../Assets/Icons/circle-dark.svg');
}

.skill-header-container {
  margin-bottom: 80px;
}

.skill-header {

  font-family: 'Centra';
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}

.skill-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.skill-image {
  height: auto;
  width: 100px;
}