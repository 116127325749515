.footer-dark {
  background-color: #32325d;
  color: white;
}

.footer-light {
  background-color: rgba(255, 215, 118, 1);
  color: black;
}

#footer .card{
  border-radius: 0%;
  font-family: "Centra";
}