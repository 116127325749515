#projects {
  font-family: 'Centra';
  padding-bottom: 60px;
}
.project-desc{
  font-size: 1.2rem;
}

.project-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  margin-right: 80px;
}

.projects-dark {
  background-color: #32325d;
  color: white;
}

.projects-light {
  background-color: rgba(255, 215, 118, 1);
  color: black;
}

.project-links .btn p {
  display: flex;
  align-items: center;
  margin: 0;
}

.project-links .btn svg {
  margin-right: 5px;
}

.project-links {
  display: flex;
  justify-content: space-around;
}

.project-links a {
  margin-right: 10px;
}