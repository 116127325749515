.banner {
  margin-top: 0;
  padding: 260px 0 100px 0;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

.banner-light {
  color: black;
  background-image: url('../../Assets/Icons/circle-light.svg');
}

.banner-dark {
  color: white;
  background-image: url('../../Assets/Icons/circle-dark.svg');
}

.tagline-dark {
  background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
}

.tagline-light {
  background: linear-gradient(45deg,
      hsl(1deg 67% 48%) 0%,
      hsl(17deg 77% 50%) 10%,
      hsl(29deg 94% 49%) 20%,
      hsl(38deg 100% 49%) 30%,
      hsl(46deg 100% 50%) 40%,
      hsl(55deg 100% 50%) 50%,
      hsl(51deg 100% 67%) 60%,
      hsl(47deg 100% 77%) 70%,
      hsl(42deg 100% 85%) 80%,
      hsl(36deg 100% 93%) 90%,
      hsl(15deg 100% 99%) 100%);

}

.tagline {
  font-family: 'Centra';
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;

  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}

.banner h1 {
  font-family: 'Centra';
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}

.banner p {
  font-size: 28px;
  font-family: 'Centra';
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}

.banner img {
  width: 100%;
  height: auto;
}

.banner img {
  animation: updown 3s linear infinite;
}

@keyframes updown {
  0% {
    transform: translateY(-40px);
  }

  50% {
    transform: translateY(40px);
  }

  100% {
    transform: translateY(-40px);
  }
}