@font-face {
	font-family: "Archivo Black";
	src: url('./Assets/Fonts/ArchivoBlack-Regular.ttf');
	font-weight: 400;
}
@font-face {
	font-family: Centra;
	src: url('./Assets/Fonts/CentraNo2-Bold.ttf');
	font-weight: 700;
}
@font-face {
	font-family: Centra;
	src: url('./Assets/Fonts/CentraNo2-Medium.ttf');
	font-weight: 500;
}
@font-face {
	font-family: Centra;
	src: url('./Assets/Fonts/CentraNo2-Book.ttf');
	font-weight: 400;
}